<template>
    <default-layout>
        <b-loading :active="loading"></b-loading>
        <UserAddEditModal
            :opened="showModal"
            :user="editingUser"
            @close="showModal = !showModal"
        />
        <header class="pageHeader">
            <h1 class="pageTitle">
                <i class="fas fa-users"></i> Team members ({{ users.length }})
            </h1>
        </header>
        <b-button type="is-primary mb-5" icon-left="plus" @click="addUser">
            Add
        </b-button>
        <table class="table is-fullwidth is-striped" v-if="!loading">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Joined</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, index) in users" :key="index">
                    <td>
                        <span class="scoop-block">{{ user.name }}</span>
                        <b-icon
                            v-if="user.id === authUser.id"
                            icon="user"
                            title="You"
                        ></b-icon>
                    </td>
                    <td>
                        <span class="scoop-block">{{ user.email }}</span>
                    </td>
                    <td>
                        <b-taglist>
                            <b-tag v-if="user.active">
                                Active
                            </b-tag>
                            <b-tag class="is-danger" v-else>
                                Deactivated
                            </b-tag>
                            <b-tag v-if="user.roles.owner">
                                Owner
                            </b-tag>
                            <b-tag v-if="user.roles.admin">
                                Admin
                            </b-tag>
                        </b-taglist>
                    </td>
                    <td>{{ user.joinDt }}</td>
                    <td>
                        <b-button
                            type="is-white"
                            icon-right="pen"
                            @click="editUser(user)"
                        ></b-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </default-layout>
</template>

<script>
import UserAddEditModal from '@/components/Modal/UserAddEditModal'
import { mapGetters } from 'vuex'

export default {
    name: 'Users',
    components: { UserAddEditModal },
    data() {
        return {
            loading: false,
            showModal: false,
            editingUser: null,
        }
    },
    computed: {
        ...mapGetters({ authUser: 'account/user', users: 'account/users' }),
    },
    async created() {
        this.loading = true
        const { success, message } = await this.$store.dispatch(
            'account/loadUsers'
        )
        this.loading = false
        !success && this.$toast.error(message)
    },
    methods: {
        addUser() {
            this.editingUser = null
            this.showModal = true
        },
        editUser(user) {
            this.editingUser = user
            this.showModal = true
        },
    },
}
</script>
