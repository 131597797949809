<template>
    <b-modal has-modal-card :active="opened">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    {{ user ? 'Edit' : 'Add' }} team member
                </p>
                <button
                    class="delete"
                    aria-label="close"
                    @click="close"
                ></button>
            </header>
            <form @submit.prevent="save">
                <section class="modal-card-body">
                    <div class="field" v-if="!user || user.id !== authUser.id">
                        <label class="label">Active</label>
                        <div class="control">
                            <b-switch v-model="form.active"></b-switch>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Name</label>
                        <div class="control">
                            <input
                                type="text"
                                class="input"
                                maxlength="50"
                                required
                                v-model="form.name"
                            />
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Email</label>
                        <div class="control">
                            <input
                                type="email"
                                class="input mb-3"
                                maxlength="255"
                                required
                                v-model="form.email"
                            />
                        </div>
                        <article class="message is-warning">
                            <div class="message-body">
                                <b-icon icon="info-circle"></b-icon>
                                This must be a <strong>Gmail</strong> or
                                <strong>Google Workplace</strong>
                                email address.
                            </div>
                        </article>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button type="button" class="button is-text" @click="close">
                        Cancel
                    </button>
                    <b-button
                        native-type="submit"
                        type="is-primary"
                        :loading="saving"
                    >
                        Save
                    </b-button>
                </footer>
            </form>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

const defaultForm = {
    name: null,
    email: null,
    active: true,
}

export default {
    name: 'UserAddEditModal',
    props: {
        opened: {
            type: Boolean,
        },
        user: {
            type: Object,
        },
    },
    data() {
        return {
            saving: false,
            form: { ...defaultForm },
        }
    },
    computed: {
        ...mapGetters({ authUser: 'account/user' }),
    },
    watch: {
        opened() {
            if (!this.opened) {
                return this.reset()
            }
            if (this.opened && this.user) {
                const { name, email, active } = this.user
                this.form = {
                    name,
                    email,
                    active,
                }
            }
        },
    },
    methods: {
        async save() {
            this.saving = true
            const { user, form } = this
            const { success, message } = user
                ? await this.$store.dispatch('account/updateUser', {
                      id: user.id,
                      data: form,
                  })
                : await this.$store.dispatch('account/addUser', form)
            this.saving = false
            if (success) {
                this.close()
                this.$toast.success('Saved')
            } else {
                this.$toast.error(message)
            }
        },
        close() {
            !this.saving && this.$emit('close')
        },
        reset() {
            this.saving = false
            this.form = {
                ...defaultForm,
            }
        },
    },
}
</script>
